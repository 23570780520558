import React from 'react';
// import sections
import AboutHer from '../components/sections/AboutHer'

const AboutUs = () => {

  return (
      <AboutHer />
  );
}

export default AboutUs;