import React from 'react';

function NotFound() {

    return(
        <div>
            <h1 className="title_notfound1">404</h1>
            <h1 className="title_notfound">Page Not Found</h1>
        </div>
    );
}
export default NotFound;