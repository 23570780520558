import React from 'react';
// import sections
import ContactHer from '../components/sections/ContactHer'

const ContactUs = () => {

  return (
      <ContactHer />
  );
}

export default ContactUs;