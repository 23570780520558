import React from 'react';

import CommunitySplit from '../components/sections/CommunitySplit';

const Community = () => {

  return (
    <CommunitySplit />
  );
}

export default Community;